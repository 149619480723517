define("webapp/locales/nl/translations", ["exports", "webapp/locales/nl/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "search.searchBarPlaceholderMobile": "product zoeken",
    'application.ui.back': 'back',
    "search.searchBarPlaceholderJobs": "Zoek naar banen of in hun beschrijving.",
    "search.searchBarPlaceholderEvents": "Zoek naar data of evenementen.",
    "search.searchBarPlaceholderDefault": "Zoek...",
    "search.searchBarPlaceholderGeoSearch": "Uw locatie...",
    "search.backToResults": "Terug naar de resultatenlijst",
    "noResults": "Geen resultaten",
    "blog.ui.noCategory": "Blog bericht",
    "widgets.categoryFilter.reset": "Filter resetten",
    "widgets.shoppingCart.wishlist": "Memo en verlanglijstje",
    "widgets.shoppingCart.cart": "Winkelwagen",
    "entries.entry.ui.products.allCategories": "Toon alle producten",
    "entries.entry.ui.products.detail": "Variant kiezen",
    "entries.entry.ui.products.notOrderable": "kan op dit moment niet besteld worden.",
    "entries.entry.ui.products.checkOutShow": "Toon winkelwagen",
    "entries.entry.ui.products.checkOut": "Ga verder naar de kassa",
    "entries.entry.ui.products.chooseVariant": "Selecteer een variant",
    "entries.entry.ui.products.chooseVariantMobile": "← selectie",
    "entries.entry.ui.products.notAvailable": "niet beschikbaar",
    "entries.entry.ui.products.crosselling.title": "Producten die u zouden kunnen interesseren",
    "entries.entry.ui.products.relatedArticles.title": "Verdere informatie over dit product",
    "entries.entry.ui.products.delivery.red": "Product momenteel niet beschikbaar",
    "entries.entry.ui.products.delivery.yellow": "Product in voorraad. Mogelijk langere leveringstijd.",
    "entries.entry.ui.products.delivery.green": "Product in voorraad in voldoende hoeveelheid",
    "entries.products.ui.combinationNotAvailable": "Product niet beschikbaar in deze combinatie",
    "search.searchBarPlaceholder": "Zoek naar...",
    "product.ui.content": "Inhoud",
    "product.ui.ingredients": "Inhoud",
    "product.ui.ingredientsFood": "Ingrediënten",
    "product.ui.storage": "Instructies voor opslag",
    "product.ui.taste": "Proef",
    "product.ui.origin": "Bee Happy Garantie",
    "product.ui.dosage": "Aanbevolen dosering",
    "product.ui.application": "Aanbeveling voor gebruik",
    "product.ui.misc": "Andere informatie",
    "product.ui.technicalData": "Technische gegevens en nadere informatie",
    "product.ui.pcs": "Stuk",
    "product.ui.bought": "gekocht",
    "product.ui.boughtLatest": "laatste",
    "product.ui.boughtPre": "Je hebt dit product",
    "product.ui.collect": "Bestel nu hier en haal het op bij deze verkooppunten",
    "product.ui.crosselling": "Dit kan u ook interesseren",
    "product.ui.crossellingReverse": "Klanten die in dit product geïnteresseerd waren, kochten ook",
    "product.ui.honeyInfo": "<p><span>Honing kristalliseert! </span>Dit is een natuurlijk kwaliteitskenmerk.</p><p><span>Kristalliseert honing? </span>Plaats de pot in een waterbad met een maximum van 40°C. De honing wordt weer vloeibaar.</p>",
    "product.ui.ingredientsBio": "Ingrediënten van gecontroleerde biologische teelt",
    "product.ui.multipleSellingUnits": "Verkrijgbaar in verschillende maten",
    "product.ui.information": "Verdere informatie",
    "product.ui.ecoCertText": "BIO-certificering",
    "product.ui.deliveryInfo": "Leveringsbon",
    "application.ui.alreadyFavorite": "Dit artikel staat op je watchlist",
    "article.ui.noCategory": "Nieuws",
    "article.ui.noCategoryProduct": "Dingen om te weten",
    "category.ui.field.leasingWeekDuration": "Huurperiode",
    "category.ui.field.weeks": "Weeks",
    "category.ui.field.pcs": "Stuk",
    "category.ui.field.productSize": "Maat",
    "category.ui.field.material": "Materiaal",
    "category.ui.field.honigArt": "Honing type",
    "category.ui.field.geleRoyale": "Royal jelly Percentage",
    "category.ui.field.%": "%",
    "user.ui.registerMessage": "Maak een gebruikersaccount aan en profiteer van de vele voordelen en informatie voor onze klanten.",
    "page.ui.privacy": "Gegevensbescherming",
    "page.ui.terms": "Algemene Voorwaarden (AV)",
    "page.ui.revocation": "Voorwaarden voor opzegging",
    "checkout.delivery.deliveryDate": "en wordt verwacht geleverd te worden op",
    "checkout.delivery.standard": "Zal worden verzonden op",
    "checkout.delivery.express": "Zal vers verzonden worden op",
    "checkout.delivery.pickUp": "Afhaling ter plaatse",
    "checkout.delivery.expressNotPossible": "U hebt een factuur- of afleveradres opgegeven waarmee expresverzending niet mogelijk is.",
    "checkout.summary.withAccount": "U bestelt met de klant account instellingen van",
    "checkout.cart.checkoutWithAccount": "Afrekenen met mijn accountgegevens",
    "checkout.cart.customerLocked": "Uw klantenrekening is geblokkeerd. Neem contact op met de ondersteuning.",
    "checkout.cart.additionalCosts": "extra kosten en kortingen",
    "checkout.cart.delete": "Winkelwagen verwijderen",
    "checkout.cart.promocode": "Promo code:",
    "checkout.cart.customerComment": "Commentaar van de klant",
    "checkout.cart.addPosition": "Een offerte-item toevoegen",
    "checkout.cart.convertOffer": "omzetten in aanbod",
    "checkout.order.comment": "Uw commentaar op de bestelling",
    "checkout.promotion.info": "Heeft u een actiecode van een van onze promoties die u wilt inwisselen voor deze bestelling?",
    "checkout.promotion.notMinValue": "De minimale bestelwaarde van € {{minValue}} voor de huidige promotiecode is nog niet bereikt.",
    "checkout.promotion.placeholder": "Uw promotie code",
    "checkout.promotion.invalid": "De ingevoerde promotiecode is niet (meer) geldig",
    "checkout.promotion.redeem": "Inwisselen",
    "account.order.rebuy": "Bestel opnieuw",
    "account.ui.company": "Rekeninggegevens",
    "account.ui.companyWant": "Ik wil een zakelijke klant worden",
    "account.ui.companyIs": "U bent een zakelijke klant en {{business}} geregistreerd.",
    "account.ui.companyContact": "Contact gegevens",
    "account.ui.companyAddress": "Standaard adres",
    "account.ui.companyAddOn": "Uitgebreide informatie",
    "account.ui.companyType": "Ik wil me graag registreren als",
    "account.ui.company.upload": "Gelieve de volgende documenten te uploaden:",
    "account.ui.company.upload.Heilpraktiker": "Uw getuigschrift van alternatieve praktijk en bevestiging van praktijk (confirmation of practice).",
    "account.ui.company.upload.Apotheker": "Je vergunning als apotheker.",
    "account.ui.company.upload.Wiederverkäufer": "Uw bedrijfsvergunning.",
    "account.ui.company.healer": "HeilpraktikerIn",
    "account.ui.company.pharmacy": "Apotheek",
    "account.ui.company.reseller": "Reseller",
    "account.ui.deliveryAddress": "Afleveradres",
    'account.ui.makeDeliveryAddress': 'Maak een ander afleveradres',
    "account.company.contactName": "Standaard contact",
    "account.company.address.setDefault": "Stel dit adres in als het standaard adres",
    "account.company.address.isDefault": "Dit adres is ook het facturatie- en leveringsadres.",
    "account.company.address.isDeliveryDefault": "Dit adres is het standaard afleveradres.",
    "account.company.address.deliveryDefault": "Dit adres is het standaard afleveradres.",
    "user.ui.accountMenuCompanySettings": "Informatie over het bedrijf",
    "user.ui.accountMenuWishlist": "In mijn wachtlijst",
    "user.ui.removeFromWishlist": "Verwijderen uit watchlist",
    "user.ui.accountOrdersEmpty": "Je hebt nog niets besteld.",
    "user.ui.accountMenuWishlistEmpty": "U hebt nog geen producten aan uw notitieblok toegevoegd.",
    "user.genderToSalutation.male": "Mr.",
    "user.genderToSalutation.female": "Mevrouw.",
    "nutritionTable.title": "Voedingswaardetabel",
    "nutritionTable.info": "is een natuurproduct - alle gegevens zijn gemiddelde waarden.",
    "nutritionTable.100": "per 100 g",
    "nutritionTable.portion": "per portie/inhoud",
    "nutritionTable.energy": "Energie",
    "nutritionTable.fat": "Fat",
    "nutritionTable.fatSaturated": "daarvan verzadigde vetzuren",
    "nutritionTable.carb": "Koolhydraten",
    "nutritionTable.sugar": "daarvan suiker",
    "nutritionTable.protein": "Eiwit (Proteïnen)",
    "nutritionTable.salt": "Zout",
    "offer.ui.rejectOfferMessage": "Het aanbod is afgewezen omdat:",
    "offer.ui.offerSent": "Aanbod verzonden!",
    "offer.ui.offerRejected": "Het aanbod werd afgewezen!",
    "shoppingCart.isLocked": "Het winkelwagentje kan niet zomaar veranderen (bijvoorbeeld omdat het een aanbieding is). Na het afronden van de bestelling, werkt alles weer als vanouds.",
    "user.company.newCustomerAccount": "Een inkooporderrekening aanmaken",
    "user.company.noCustomerValidation": "Uw bestelaccount is nog niet geactiveerd.",
    "user.company.noCustomerYet": "U bent geregistreerd maar hebt nog geen bestelling geplaatst. Maak een bestelaccount aan om te kunnen bestellen."
  });
});