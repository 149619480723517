define('webapp/locales/tr/translations', ['exports', 'webapp/locales/tr/translations-base'], function (exports, _translationsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    'search.searchBarPlaceholderMobile': 'Ürün arama...',
    'application.ui.back': 'geri',
    'search.searchBarPlaceholderJobs': 'İşleri veya açıklamalarını arayın.',
    'search.searchBarPlaceholderEvents': 'Tarihleri veya etkinlikleri arayın.',
    'search.searchBarPlaceholderDefault': 'Arama...',
    'search.searchBarPlaceholderGeoSearch': 'Konumunuz...',
    'search.backToResults': 'Sonuç listesine geri dönün',
    noResults: 'Sonuç yok',
    'blog.ui.noCategory': 'Blog yazısı',
    'widgets.categoryFilter.reset': 'Filtreyi sıfırla',
    'widgets.shoppingCart.wishlist': 'Not ve dilek listesi',
    'widgets.shoppingCart.wishlist.empty': 'Not defterinizde ve istek listenizde henüz hiçbir ürün yok',
    'widgets.shoppingCart.cart': 'Alışveriş sepeti',
    'entries.entry.ui.products.allCategories': 'Tüm ürünleri göster',
    'entries.entry.ui.products.detail': 'Varyant seçin',
    'entries.entry.ui.products.notOrderable': 'şu anda sipariş edilemez.',
    'entries.entry.ui.products.checkOutShow': 'Alışveriş sepetini göster',
    'entries.entry.ui.products.checkOut': 'Ödeme işlemine devam edin',
    'entries.entry.ui.products.chooseVariant': 'Lütfen bir varyant seçin',
    'entries.entry.ui.products.chooseVariantMobile': '← seçim',
    'entries.entry.ui.products.notAvailable': 'mevcut değil',
    'entries.entry.ui.products.crosselling.title': 'İlginizi çekebilecek ürünler',
    'entries.entry.ui.products.relatedArticles.title': 'Bu ürün hakkında daha fazla bilgi',
    'entries.entry.ui.products.delivery.red': 'Ürün şu anda mevcut değil',
    'entries.entry.ui.products.delivery.yellow': 'Ürün stokta. Muhtemelen daha uzun teslimat süresi.',
    'entries.entry.ui.products.delivery.green': 'Ürünün yeterli miktarda stokta olması',
    'entries.products.ui.combinationNotAvailable': 'Ürün bu kombinasyonda mevcut değildir',
    'search.searchBarPlaceholder': 'Arayın...',
    'product.ui.content': 'İçerik',
    'product.ui.ingredients': 'İçerik',
    'product.ui.ingredientsFood': 'İçindekiler',
    'product.ui.storage': 'Depolama talimatları',
    'product.ui.taste': 'Lezzet',
    'product.ui.origin': 'Arı-Mutlu Garantisi',
    'product.ui.dosage': 'Önerilen alım miktarı',
    'product.ui.application': 'Kullanım için öneri',
    'product.ui.misc': 'Diğer bilgiler',
    'product.ui.technicalData': 'Teknik veriler ve daha fazla bilgi',
    'product.ui.pcs': 'Parça',
    'product.ui.bought': 'satın alındı',
    'product.ui.boughtLatest': 'son',
    'product.ui.boughtPre': 'Bu ürüne sahipsiniz',
    'product.ui.collect': 'Şimdi buradan sipariş verin ve bu satış noktalarından teslim alın',
    'product.ui.crosselling': 'Bu da ilginizi çekebilir',
    'product.ui.crossellingReverse': 'Bu ürünle ilgilenen müşteriler ayrıca şunları satın aldı',
    'product.ui.honeyInfo': "<p><span>Bal kristalleşir! </span>Bu doğal bir kalite özelliğidir.</p><p><span>Bal kristalleşir mi? </span>Kavanozu maksimum 40°C'lik bir su banyosuna yerleştirin. Bal tekrar sıvı hale gelir.</p>",
    'product.ui.ingredientsBio': 'Kontrollü organik yetiştiricilikten elde edilen bileşenler',
    'product.ui.multipleSellingUnits': 'Çeşitli boyutlarda mevcuttur',
    'product.ui.information': 'Daha fazla bilgi',
    'product.ui.ecoCertText': 'BIO sertifikası',
    'product.ui.deliveryInfo': 'Teslimat notu',
    'article.ui.noCategory': 'Haberler',
    'article.ui.noCategoryProduct': 'Bilinmesi gerekenler',
    'category.ui.field.leasingWeekDuration': 'Kiralama süresi',
    'category.ui.field.weeks': 'Haftalar',
    'category.ui.field.pcs': 'Parça',
    'category.ui.field.productSize': 'Boyut',
    'category.ui.field.material': 'Malzeme',
    'category.ui.field.honigArt': 'Bal türü',
    'category.ui.field.geleRoyale': 'Arı Sütü Oranı %',
    'category.ui.field.%': '%',
    'user.ui.registerMessage': 'Özel bir müşteri olarak, sipariş verebilir ve ödeme sırasında bir kullanıcı hesabına sahip olmak istediğinizi belirtebilirsiniz. <br><br>Tıbbi olmayan bir pratisyen hekim, bayi veya eczane iseniz, burada bir ticari müşteri hesabı oluşturabilir ve birçok avantaj ve bilgiden yararlanabilirsiniz. <br><br>Bir işletme hesabı için belgelerin gerekli olduğunu lütfen unutmayın.',
    'page.ui.privacy': 'Veri koruma',
    'page.ui.terms': 'Genel Hüküm ve Koşullar (GTC)',
    'page.ui.revocation': 'İptal koşulları',
    'checkout.delivery.deliveryDate': 've şu tarihte teslim edilmesi bekleniyor',
    'checkout.delivery.standard': 'Teslimata kadar',
    'checkout.delivery.express': 'Şu tarihe kadar hızlı teslimat',
    'checkout.delivery.pickUp': 'Yerinde teslim alma',
    'checkout.delivery.expressNotPossible': 'Hızlı teslimatın mümkün olmadığı bir fatura veya teslimat adresi girdiniz.',
    'checkout.summary.withAccount': 'Müşteri hesabı ayarları ile sipariş verirsiniz',
    'checkout.cart.checkoutWithAccount': 'Hesap bilgilerimle ödeme yapın',
    'checkout.cart.customerLocked': 'Müşteri hesabınız bloke edildi. Lütfen destek ekibiyle iletişime geçin.',
    'checkout.cart.additionalCosts': 'Ek maliyetler ve indirimler',
    'checkout.cart.delete': 'Alışveriş sepetini sil',
    'checkout.cart.promocode': 'Promosyon kodu:',
    'checkout.cart.customerComment': 'Müşteri yorumu',
    'checkout.cart.addPosition': 'Teklif pozisyonu ekle',
    'checkout.cart.convertOffer': 'Teklife dönüştürün',
    'checkout.order.comment': 'Sipariş hakkındaki yorumunuz',
    'checkout.promotion.info': 'Bu sipariş için kullanmak istediğiniz promosyonlarımızdan birine ait bir promosyon kodunuz var mı?',
    'checkout.promotion.notMinValue': 'Minimum sipariş değeri € {{minValue}} için geçerli promosyon koduna henüz ulaşılmamıştır.',
    'checkout.promotion.placeholder': 'Promosyon kodunuz',
    'checkout.promotion.invalid': 'Girilen promosyon kodu geçerli değil (veya artık geçerli değil)',
    'checkout.promotion.redeem': 'Kurtarmak',
    'account.order.rebuy': 'Tekrar sipariş verin',
    'account.ui.company': 'Hesap bilgileri',
    'account.ui.companyWant': 'Kurumsal müşteri olmak istiyorum',
    'account.ui.companyIs': 'Kurumsal bir müşterisiniz ve {{business}} kayıtlı.',
    'account.ui.companyContact': 'İletişim bilgileri',
    'account.ui.companyAddress': 'Standart adres',
    'account.ui.companyAddOn': 'Genişletilmiş veri',
    'account.ui.companyType': 'Şu şekilde kayıt olmak istiyorum',
    'account.ui.company.upload': 'Lütfen aşağıdaki belgeleri yükleyin:',
    'account.ui.company.upload.Heilpraktiker': 'Alternatif uygulayıcı sertifikanız ve uygulama onayınız (confirmation of practice).',
    'account.ui.company.upload.Apotheker': 'Eczacı olarak ruhsatınız.',
    'account.ui.company.upload.Wiederverkäufer': 'Ticaret ruhsatınız.',
    'account.ui.company.healer': 'Alternatif uygulayıcı',
    'account.ui.company.pharmacy': 'Eczane',
    'account.ui.company.reseller': 'Bayi',
    'account.ui.company.uploadedDocuments': 'Yüklediğiniz dosyalar',
    'account.ui.company.uploadedDocuments.info': 'Bir belge yükledikten sonra, lütfen belgeye bir başlık ve kısa bir açıklama verin. Başlık ve açıklamayı girmek için belgenin sol üst köşesindeki kalem simgesine tıklayın. Önemli: Değişikliklerinizi kaydedin.',
    'account.ui.company.uploadedDocument.title': 'Lütfen belgeye anlamlı bir başlık verin.',
    'account.ui.company.uploadedDocument.description': 'Lütfen ne tür bir belge olduğunu kısaca açıklayınız.',
    'account.ui.deliveryAddress': 'Teslimat adresi',
    'account.ui.makeDeliveryAddress': 'Dev. teslimat adresi',
    'account.company.contactName': 'Standart temas',
    'account.company.address.setDefault': 'Bu adresi varsayılan adres olarak ayarlayın',
    'account.company.address.isDefault': 'Bu adres aynı zamanda fatura ve teslimat adresidir.',
    'account.company.address.isDeliveryDefault': 'Bu adres varsayılan teslimat adresidir.',
    'account.company.address.deliveryDefault': 'Bu adres varsayılan teslimat adresidir.',
    'user.ui.accountMenuCompanySettings': 'Şirket bilgileri',
    'user.ui.accountMenuWishlist': 'İzleme listemde',
    'user.ui.removeFromWishlist': 'İzleme listesinden çıkar',
    'user.ui.accountOrdersEmpty': 'Henüz bir şey sipariş etmediniz.',
    'user.ui.accountMenuWishlistEmpty': 'Not defterinize henüz herhangi bir ürün eklemediniz.',
    'user.ui.documents': 'Ürün bilgileri',
    'user.genderToSalutation.male': 'Bay',
    'user.genderToSalutation.female': 'Bayan',
    'nutritionTable.title': 'Beslenme tablosu',
    'nutritionTable.info': 'doğal bir üründür - Tüm veriler ortalama değerlerdir.',
    'nutritionTable.100': '100 g başına',
    'nutritionTable.portion': 'porsiyon/içerik başına',
    'nutritionTable.energy': 'Enerji',
    'nutritionTable.fat': 'Şişman',
    'nutritionTable.fatSaturated': 'bunların doymuş yağ asitleri',
    'nutritionTable.carb': 'Karbonhidratlar',
    'nutritionTable.sugar': 'bunların şekeri',
    'nutritionTable.protein': 'Protein (proteinler)',
    'nutritionTable.salt': 'Tuz',
    'offer.ui.rejectOfferMessage': 'Teklif reddedildi çünkü:',
    'offer.ui.offerSent': 'Teklif gönderildi!',
    'offer.ui.offerRejected': 'Teklif reddedildi!',
    'shoppingCart.isLocked': 'Alışveriş sepeti şu anda değiştirilemez (örneğin bir teklif olduğu için). Siparişi tamamladıktan sonra her şey yine normal şekilde çalışır.',
    'user.company.newCustomerAccount': 'Bir sipariş hesabı oluşturun',
    'user.company.noCustomerValidation': 'Sipariş hesabınız henüz etkinleştirilmedi.',
    'user.company.noCustomerYet': 'Kaydoldunuz ancak henüz bir sipariş vermediniz. Sipariş verebilmek için bir sipariş hesabı oluşturun.',
    'country.DE': 'Yurtiçi',
    'country.EU': 'AB yabancı ülkeleri',
    'country.NONEU': 'Dünya çapında'
  });
});