define("webapp/locales/fr/translations", ["exports", "webapp/locales/fr/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "search.searchBarPlaceholderMobile": "recherche de produits",
    'application.ui.back': 'retour',
    "search.searchBarPlaceholderJobs": "Recherchez des emplois ou dans leur description.",
    "search.searchBarPlaceholderEvents": "Recherchez des dates ou des événements.",
    "search.searchBarPlaceholderDefault": "Rechercher...",
    "search.searchBarPlaceholderGeoSearch": "Votre emplacement...",
    "search.backToResults": "Retour à la liste des résultats",
    "noResults": "Aucun résultat",
    "blog.ui.noCategory": "Blog post",
    "widgets.categoryFilter.reset": "Réinitialiser le filtre",
    "widgets.shoppingCart.wishlist": "Mémo et liste de souhaits",
    "widgets.shoppingCart.cart": "Panier d'achat",
    "entries.entry.ui.products.allCategories": "Afficher tous les produits",
    "entries.entry.ui.products.detail": "Sélectionner la variante",
    "entries.entry.ui.products.notOrderable": "ne peut être commandé pour le moment.",
    "entries.entry.ui.products.checkOutShow": "Afficher le panier d'achat",
    "entries.entry.ui.products.checkOut": "Passez à la caisse",
    "entries.entry.ui.products.chooseVariant": "Veuillez choisir une variante",
    "entries.entry.ui.products.chooseVariantMobile": "← sélection",
    "entries.entry.ui.products.notAvailable": "non disponible",
    "entries.entry.ui.products.crosselling.title": "Produits qui pourraient vous intéresser",
    "entries.entry.ui.products.relatedArticles.title": "Informations complémentaires sur ce produit",
    "entries.entry.ui.products.delivery.red": "Produit actuellement non disponible",
    "entries.entry.ui.products.delivery.yellow": "Produit en stock. Délai de livraison éventuellement plus long.",
    "entries.entry.ui.products.delivery.green": "Produit en stock en quantité suffisante",
    "entries.products.ui.combinationNotAvailable": "Produit non disponible dans cette combinaison",
    "search.searchBarPlaceholder": "Recherche de...",
    "product.ui.content": "Contenu",
    "product.ui.ingredients": "Contenu",
    "product.ui.ingredientsFood": "Ingrédients",
    "product.ui.storage": "Instructions de stockage",
    "product.ui.taste": "Goûter",
    "product.ui.origin": "Garantie Bee Happy",
    "product.ui.dosage": "Dosage recommandé",
    "product.ui.application": "Recommandation d'utilisation",
    "product.ui.misc": "Autres informations",
    "product.ui.technicalData": "Données techniques et autres informations",
    "product.ui.pcs": "Pièce",
    "product.ui.bought": "acheté",
    "product.ui.boughtLatest": "dernier",
    "product.ui.boughtPre": "Vous avez ce produit",
    "product.ui.collect": "Commandez maintenant ici et retirez-vous dans les points de vente suivants",
    "product.ui.crosselling": "Ceci pourrait également vous intéresser",
    "product.ui.crossellingReverse": "Les clients qui ont été intéressés par ce produit ont également acheté",
    "product.ui.honeyInfo": "<p><span>Le miel se cristallise ! </span>Il s'agit d'une caractéristique de qualité naturelle.</p><p><span>Le miel se cristallise ? </span>Placez le bocal dans un bain-marie dont la température maximale est de 40°C. Le miel redevient liquide.</p>",
    "product.ui.ingredientsBio": "Ingrédients issus de la culture biologique contrôlée",
    "product.ui.multipleSellingUnits": "Disponible en plusieurs tailles",
    "product.ui.information": "Plus d'informations",
    "product.ui.ecoCertText": "Certification BIO",
    "product.ui.deliveryInfo": "Bon de livraison",
    "application.ui.alreadyFavorite": "Cet article est sur votre liste de surveillance",
    "article.ui.noCategory": "Nouvelles",
    "article.ui.noCategoryProduct": "Ce qu'il faut savoir",
    "category.ui.field.leasingWeekDuration": "Période de location",
    "category.ui.field.weeks": "Semaines",
    "category.ui.field.pcs": "Pièce",
    "category.ui.field.productSize": "Taille",
    "category.ui.field.material": "Matériau",
    "category.ui.field.honigArt": "Type de miel",
    "category.ui.field.geleRoyale": "Gelée royale Pourcentage",
    "category.ui.field.%": "%",
    "user.ui.registerMessage": "Créez un compte utilisateur et bénéficiez de nombreux avantages et informations pour nos clients.",
    "page.ui.privacy": "Protection des données",
    "page.ui.terms": "Conditions générales de vente (CGV)",
    "page.ui.revocation": "Conditions de retrait",
    "checkout.delivery.deliveryDate": "et devrait être livré le",
    "checkout.delivery.standard": "Sera expédié le",
    "checkout.delivery.express": "Seront expédiés frais le",
    "checkout.delivery.pickUp": "Ramassage sur place",
    "checkout.delivery.expressNotPossible": "Vous avez saisi une adresse de facturation ou de livraison avec laquelle l'envoi express n'est pas possible.",
    "checkout.summary.withAccount": "Vous commandez avec les paramètres du compte client de",
    "checkout.cart.checkoutWithAccount": "Commander avec les détails de mon compte",
    "checkout.cart.customerLocked": "Votre compte client a été verrouillé. Veuillez contacter le support.",
    "checkout.cart.additionalCosts": "",
    "checkout.cart.delete": "Supprimer le panier d'achat",
    "checkout.cart.promocode": "Code promo :",
    "checkout.cart.customerComment": "Commentaire du client",
    "checkout.cart.addPosition": "Ajouter un élément de devis",
    "checkout.cart.convertOffer": "convertir en offre",
    "checkout.order.comment": "Votre commentaire sur l'ordonnance",
    "checkout.promotion.info": "Avez-vous un code promotionnel issu de l'une de nos promotions que vous souhaitez utiliser pour cette commande ?",
    "checkout.promotion.placeholder": "Votre code de promotion",
    "checkout.promotion.notMinValue": "Le montant minimum de commande de {{minValue}} € pour le code promo actuel n'est pas encore atteint.",
    "checkout.promotion.invalid": "Le code promotionnel saisi n'est pas (ou plus) valable.",
    "checkout.promotion.redeem": "Rédiger",
    "account.order.rebuy": "Commander à nouveau",
    "account.ui.company": "Détails du compte",
    "account.ui.companyWant": "Je veux devenir une entreprise cliente",
    "account.ui.companyIs": "Vous êtes une entreprise cliente et {{business}} enregistré.",
    "account.ui.companyContact": "Coordonnées de contact",
    "account.ui.companyAddress": "Adresse standard",
    "account.ui.companyAddOn": "Informations complémentaires",
    "account.ui.companyType": "Je souhaite m'inscrire en tant que",
    "account.ui.company.upload": "Veuillez télécharger les documents suivants :",
    "account.ui.company.upload.Heilpraktiker": "Votre certificat de praticien alternatif et votre confirmation de pratique (confirmation de pratique).",
    "account.ui.company.upload.Apotheker": "Votre licence de pharmacien.",
    "account.ui.company.upload.Wiederverkäufer": "Votre licence professionnelle.",
    "account.ui.company.healer": "HeilpraktikerIn",
    "account.ui.company.pharmacy": "Pharmacie",
    "account.ui.company.reseller": "Revendeur",
    "account.ui.deliveryAddress": "Adresse de livraison",
    'account.ui.makeDeliveryAddress': 'Créer une adresse de livraison',
    "account.company.contactName": "Contact standard",
    "account.company.address.setDefault": "Définir cette adresse comme l'adresse par défaut",
    "account.company.address.isDefault": "Cette adresse est également l'adresse de facturation et de livraison.",
    "account.company.address.isDeliveryDefault": "Cette adresse est l'adresse de livraison par défaut.",
    "account.company.address.deliveryDefault": "Cette adresse est l'adresse de livraison par défaut.",
    "user.ui.accountMenuCompanySettings": "Informations sur l'entreprise",
    "user.ui.accountMenuWishlist": "Dans ma liste de surveillance",
    "user.ui.removeFromWishlist": "Retirer de la liste de surveillance",
    "user.ui.accountOrdersEmpty": "Vous n'avez encore rien commandé.",
    "user.ui.accountMenuWishlistEmpty": "Vous n'avez encore ajouté aucun produit à votre bloc-notes.",
    "user.genderToSalutation.male": "Monsieur.",
    "user.genderToSalutation.female": "Mme.",
    "nutritionTable.title": "Tableau nutritionnel",
    "nutritionTable.info": "est un produit naturel - toutes les données sont des valeurs moyennes.",
    "nutritionTable.100": "par 100 g",
    "nutritionTable.portion": "par portion/contenu",
    "nutritionTable.energy": "Énergie",
    "nutritionTable.fat": "Graisse",
    "nutritionTable.fatSaturated": "ses acides gras saturés",
    "nutritionTable.carb": "Glucides",
    "nutritionTable.sugar": "son sucre",
    "nutritionTable.protein": "Protéine (Protéines)",
    "nutritionTable.salt": "Sel",
    "offer.ui.rejectOfferMessage": "L'offre est rejetée parce que :",
    "offer.ui.offerSent": "Offre expédiée !",
    "offer.ui.offerRejected": "L'offre a été rejetée !",
    "shoppingCart.isLocked": "Le panier d'achat ne peut pas simplement changer (par exemple parce qu'il s'agit d'une offre). Après avoir terminé la commande, tout fonctionne à nouveau normalement.",
    "user.company.newCustomerAccount": "Créer un compte de commande",
    "user.company.noCustomerValidation": "Votre compte de commande n'a pas encore été activé.",
    "user.company.noCustomerYet": "Vous êtes inscrit mais vous n'avez pas encore passé de commande. Créez un compte pour pouvoir commander."
  });
});