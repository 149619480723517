define("webapp/locales/es/translations", ["exports", "webapp/locales/es/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "search.searchBarPlaceholderMobile": "búsqueda de productos",
    'application.ui.back': 'back',
    "search.searchBarPlaceholderJobs": "Busque los puestos de trabajo o en su descripción.",
    "search.searchBarPlaceholderEvents": "Búsqueda de fechas o eventos.",
    "search.searchBarPlaceholderDefault": "Buscar...",
    "search.searchBarPlaceholderGeoSearch": "Su ubicación...",
    "search.backToResults": "Volver a la lista de resultados",
    "noResults": "No hay resultados",
    "blog.ui.noCategory": "Entrada en el blog",
    "widgets.categoryFilter.reset": "Reiniciar el filtro",
    "widgets.shoppingCart.wishlist": "Nota y lista de deseos",
    "widgets.shoppingCart.cart": "Cesta de la compra",
    "entries.entry.ui.products.allCategories": "Mostrar todos los productos",
    "entries.entry.ui.products.detail": "Seleccione la variante",
    "entries.entry.ui.products.notOrderable": "no se puede pedir por el momento.",
    "entries.entry.ui.products.checkOutShow": "Mostrar la cesta de la compra",
    "entries.entry.ui.products.checkOut": "Proceder a la compra",
    "entries.entry.ui.products.chooseVariant": "Seleccione una variante",
    "entries.entry.ui.products.chooseVariantMobile": "← selección",
    "entries.entry.ui.products.notAvailable": "no disponible",
    "entries.entry.ui.products.crosselling.title": "Productos que pueden interesarle",
    "entries.entry.ui.products.relatedArticles.title": "Más información sobre este producto",
    "entries.entry.ui.products.delivery.red": "Producto no disponible actualmente",
    "entries.entry.ui.products.delivery.yellow": "Producto en stock. Posiblemente un plazo de entrega más largo.",
    "entries.entry.ui.products.delivery.green": "Producto en stock en cantidad suficiente",
    "entries.products.ui.combinationNotAvailable": "Producto no disponible en esta combinación",
    "search.searchBarPlaceholder": "Buscar...",
    "product.ui.content": "Contenido",
    "product.ui.ingredients": "Contenido",
    "product.ui.ingredientsFood": "Ingredientes",
    "product.ui.storage": "Instrucciones de almacenamiento",
    "product.ui.taste": "Prueba",
    "product.ui.origin": "Garantía Bee Happy",
    "product.ui.dosage": "Dosificación recomendada",
    "product.ui.application": "Recomendación de uso",
    "product.ui.misc": "Otros datos",
    "product.ui.technicalData": "Datos técnicos e información adicional",
    "product.ui.pcs": "Pieza",
    "product.ui.bought": "comprado",
    "product.ui.boughtLatest": "último",
    "product.ui.boughtPre": "Tiene este producto",
    "product.ui.collect": "Pídalo ahora aquí y recójalo en estos puntos de venta",
    "product.ui.crosselling": "Esto también podría interesarle",
    "product.ui.crossellingReverse": "Los clientes que se interesaron por este producto también compraron",
    "product.ui.honeyInfo": "<p><span>¡La miel se cristaliza! </span>Se trata de una característica de calidad natural.</p><p><span>¿La miel se cristaliza? </span>Colocar el frasco en un baño de agua con un máximo de 40°C. La miel vuelve a ser líquida.</p>",
    "product.ui.ingredientsBio": "Ingredientes procedentes de cultivos ecológicos controlados",
    "product.ui.multipleSellingUnits": "Disponible en varios tamaños",
    "product.ui.information": "Más información",
    "product.ui.ecoCertText": "Certificación BIO",
    "product.ui.deliveryInfo": "Albarán de entrega",
    "application.ui.alreadyFavorite": "Este artículo está en su lista de seguimiento",
    "article.ui.noCategory": "Noticias",
    "article.ui.noCategoryProduct": "Lo que hay que saber",
    "category.ui.field.leasingWeekDuration": "Periodo de alquiler",
    "category.ui.field.weeks": "Semanas",
    "category.ui.field.pcs": "Pieza",
    "category.ui.field.productSize": "Tamaño",
    "category.ui.field.material": "Material",
    "category.ui.field.honigArt": "Tipo de miel",
    "category.ui.field.geleRoyale": "Jalea real Porcentaje",
    "category.ui.field.%": "%",
    "user.ui.registerMessage": "Cree una cuenta de usuario y benefíciese de muchas ventajas e información para nuestros clientes.",
    "page.ui.privacy": "Protección de datos",
    "page.ui.terms": "Condiciones Generales de Contratación (CGC)",
    "page.ui.revocation": "Condiciones de retirada",
    "checkout.delivery.deliveryDate": "y se espera que se entregue el",
    "checkout.delivery.standard": "Se enviará el",
    "checkout.delivery.express": "Se enviará fresco el",
    "checkout.delivery.pickUp": "Recogida in situ",
    "checkout.delivery.expressNotPossible": "Ha introducido una dirección de facturación o de envío con la que no es posible el envío exprés.",
    "checkout.summary.withAccount": "El pedido se realiza con la configuración de la cuenta de cliente de",
    "checkout.cart.checkoutWithAccount": "Pago con los datos de mi cuenta",
    "checkout.cart.customerLocked": "Su cuenta de cliente ha sido bloqueada. Póngase en contacto con el servicio de asistencia.",
    "checkout.cart.additionalCosts": "costes adicionales y descuentos",
    "checkout.cart.delete": "Borrar la cesta de la compra",
    "checkout.cart.promocode": "Código promocional:",
    "checkout.cart.customerComment": "Comentario del cliente",
    "checkout.cart.addPosition": "Añadir un elemento de cotización",
    "checkout.cart.convertOffer": "convertir en oferta",
    "checkout.order.comment": "Su comentario sobre el pedido",
    "checkout.promotion.info": "¿Tiene un código promocional de una de nuestras promociones que le gustaría canjear por este pedido?",
    "checkout.promotion.placeholder": "Su código de promoción",
    'checkout.promotion.notMinValue': 'El valor mínimo del pedido de {{minValue}} € para el código promocional actual aún no se ha alcanzado.',
    "checkout.promotion.invalid": "El código de promoción introducido no es (o ya no es) válido",
    "checkout.promotion.redeem": "Canjee",
    "account.order.rebuy": "Volver a pedir",
    "account.ui.company": "Detalles de la cuenta",
    "account.ui.companyWant": "Quiero ser cliente de una empresa",
    "account.ui.companyIs": "Usted es un cliente corporativo y {{business}} registrado.",
    "account.ui.companyContact": "Datos de contacto",
    "account.ui.companyAddress": "Dirección estándar",
    "account.ui.companyAddOn": "Información ampliada",
    "account.ui.companyType": "Me gustaría registrarme como",
    "account.ui.company.upload": "Por favor, cargue los siguientes documentos:",
    "account.ui.company.upload.Heilpraktiker": "Su certificado de médico alternativo y la confirmación de la práctica (confirmación de la práctica).",
    "account.ui.company.upload.Apotheker": "Su licencia de farmacéutico.",
    "account.ui.company.upload.Wiederverkäufer": "Su licencia comercial.",
    "account.ui.company.healer": "HeilpraktikerEn",
    "account.ui.company.pharmacy": "Farmacia",
    "account.ui.company.reseller": "Revendedor",
    "account.ui.deliveryAddress": "Dirección de entrega",
    'account.ui.makeDeliveryAddress': 'Crear una dirección de entrega diferente',
    "account.company.contactName": "Contacto estándar",
    "account.company.address.setDefault": "Establecer esta dirección como la dirección por defecto",
    "account.company.address.isDefault": "Esta dirección es también la de facturación y entrega.",
    "account.company.address.isDeliveryDefault": "Esta dirección es la dirección de entrega por defecto.",
    "account.company.address.deliveryDefault": "Esta dirección es la dirección de entrega por defecto.",
    "user.ui.accountMenuCompanySettings": "Información de la empresa",
    "user.ui.accountMenuWishlist": "En mi lista de vigilancia",
    "user.ui.removeFromWishlist": "Eliminar de la lista de vigilancia",
    "user.ui.accountOrdersEmpty": "Todavía no has pedido nada.",
    "user.ui.accountMenuWishlistEmpty": "Todavía no ha añadido ningún producto a su bloc de notas.",
    "user.genderToSalutation.male": "El Sr.",
    "user.genderToSalutation.female": "La Sra.",
    "nutritionTable.title": "Tabla de nutrición",
    "nutritionTable.info": "es un producto natural: todos los datos son valores medios.",
    "nutritionTable.100": "por 100 g",
    "nutritionTable.portion": "por porción/contenido",
    "nutritionTable.energy": "Energía",
    "nutritionTable.fat": "Grasa",
    "nutritionTable.fatSaturated": "de los mismos ácidos grasos saturados",
    "nutritionTable.carb": "Carbohidratos",
    "nutritionTable.sugar": "del mismo azúcar",
    "nutritionTable.protein": "Proteínas",
    "nutritionTable.salt": "Sal",
    "offer.ui.rejectOfferMessage": "La oferta se rechaza porque:",
    "offer.ui.offerSent": "Oferta enviada!",
    "offer.ui.offerRejected": "La oferta fue rechazada.",
    "shoppingCart.isLocked": "La cesta de la compra no puede cambiar sin más (por ejemplo, porque es una oferta). Tras completar el pedido, todo vuelve a funcionar con normalidad.",
    "user.company.newCustomerAccount": "Crear una cuenta de pedido",
    "user.company.noCustomerValidation": "Su cuenta de pedido aún no ha sido activada.",
    "user.company.noCustomerYet": "Está registrado pero aún no ha realizado ningún pedido. Cree una cuenta de pedido para poder realizarlo."
  });
});