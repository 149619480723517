define("webapp/locales/it/translations", ["exports", "webapp/locales/it/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "search.searchBarPlaceholderMobile": "ricerca prodotto",
    'application.ui.back': 'indietro',
    "search.searchBarPlaceholderJobs": "Cerca i lavori o nella loro descrizione.",
    "search.searchBarPlaceholderEvents": "Cerca per date o eventi.",
    "search.searchBarPlaceholderDefault": "Cerca...",
    "search.searchBarPlaceholderGeoSearch": "La tua posizione...",
    "search.backToResults": "Torna alla lista dei risultati",
    "noResults": "Nessun risultato",
    "blog.ui.noCategory": "Messaggio sul blog",
    "widgets.categoryFilter.reset": "Azzera il filtro",
    "widgets.shoppingCart.wishlist": "Memo e lista dei desideri",
    "widgets.shoppingCart.cart": "Carrello della spesa",
    "entries.entry.ui.products.allCategories": "Mostra tutti i prodotti",
    "entries.entry.ui.products.detail": "Seleziona la variante",
    "entries.entry.ui.products.notOrderable": "non può essere ordinato al momento.",
    "entries.entry.ui.products.checkOutShow": "Mostra il carrello della spesa",
    "entries.entry.ui.products.checkOut": "Procedere alla cassa",
    "entries.entry.ui.products.chooseVariant": "Selezionare una variante",
    "entries.entry.ui.products.chooseVariantMobile": "← selezione",
    "entries.entry.ui.products.notAvailable": "non disponibile",
    "entries.entry.ui.products.crosselling.title": "Prodotti che potrebbero interessarti",
    "entries.entry.ui.products.relatedArticles.title": "Ulteriori informazioni su questo prodotto",
    "entries.entry.ui.products.delivery.red": "Prodotto attualmente non disponibile",
    "entries.entry.ui.products.delivery.yellow": "Prodotto in stock. Possibilmente tempi di consegna più lunghi.",
    "entries.entry.ui.products.delivery.green": "Prodotto in stock in quantità sufficiente",
    "entries.products.ui.combinationNotAvailable": "Prodotto non disponibile in questa combinazione",
    "search.searchBarPlaceholder": "Cerca...",
    "product.ui.content": "Contenuto",
    "product.ui.ingredients": "Contenuto",
    "product.ui.ingredientsFood": "Ingredienti",
    "product.ui.storage": "Istruzioni per la conservazione",
    "product.ui.taste": "Gusto",
    "product.ui.origin": "Garanzia Bee Happy",
    "product.ui.dosage": "Dosaggio raccomandato",
    "product.ui.application": "Raccomandazione per l'uso",
    "product.ui.misc": "Altre informazioni",
    "product.ui.technicalData": "Dati tecnici e ulteriori informazioni",
    "product.ui.pcs": "Pezzo",
    "product.ui.bought": "acquistato",
    "product.ui.boughtLatest": "ultimo",
    "product.ui.boughtPre": "Hai questo prodotto",
    "product.ui.collect": "Ordina ora qui e ritira in questi punti vendita",
    "product.ui.crosselling": "Questo potrebbe anche interessarti",
    "product.ui.crossellingReverse": "I clienti che erano interessati a questo prodotto hanno anche comprato",
    "product.ui.honeyInfo": "<p><span>Il miele si cristallizza! </span>Questa è una caratteristica di qualità naturale.</p><p><span>Il miele si cristallizza? </span>Mettere il barattolo in un bagno d'acqua con un massimo di 40°C. Il miele diventa di nuovo liquido.</p>",
    "product.ui.ingredientsBio": "Ingredienti da coltivazione biologica controllata",
    "product.ui.multipleSellingUnits": "Disponibile in diverse misure",
    "product.ui.information": "Ulteriori informazioni",
    "product.ui.ecoCertText": "Certificazione BIO",
    "product.ui.deliveryInfo": "Bolla di consegna",
    "application.ui.alreadyFavorite": "Questo articolo è sulla tua watchlist",
    "article.ui.noCategory": "Notizie",
    "article.ui.noCategoryProduct": "Cose da sapere",
    "category.ui.field.leasingWeekDuration": "Periodo di noleggio",
    "category.ui.field.weeks": "Settimane",
    "category.ui.field.pcs": "Pezzo",
    "category.ui.field.productSize": "Dimensione",
    "category.ui.field.material": "Materiale",
    "category.ui.field.honigArt": "Tipo di miele",
    "category.ui.field.geleRoyale": "Percentuale di pappa reale",
    "category.ui.field.%": "%",
    "user.ui.registerMessage": "Crea un account utente e approfitta di molti vantaggi e informazioni per i nostri clienti.",
    "page.ui.privacy": "Protezione dei dati",
    "page.ui.terms": "Termini e condizioni generali (GTC)",
    "page.ui.revocation": "Condizioni di ritiro",
    "checkout.delivery.deliveryDate": "e dovrebbe essere consegnato il",
    "checkout.delivery.standard": "Sarà spedito il",
    "checkout.delivery.express": "Sarà spedito fresco su",
    "checkout.delivery.pickUp": "Ritiro in loco",
    "checkout.delivery.expressNotPossible": "Hai inserito un indirizzo di fatturazione o di spedizione con il quale la spedizione espressa non è possibile.",
    "checkout.summary.withAccount": "Si ordina con le impostazioni dell'account cliente di",
    "checkout.cart.checkoutWithAccount": "Checkout con i dettagli del mio account",
    "checkout.cart.customerLocked": "Il tuo account cliente è stato bloccato. Si prega di contattare il supporto.",
    "checkout.cart.additionalCosts": "costi aggiuntivi e sconti",
    "checkout.cart.delete": "Cancellare il carrello",
    "checkout.cart.promocode": "Codice promozionale:",
    "checkout.cart.customerComment": "Commento del cliente",
    "checkout.cart.addPosition": "Aggiungere un elemento del preventivo",
    "checkout.cart.convertOffer": "convertire in offerta",
    "checkout.order.comment": "Il tuo commento sull'ordine",
    "checkout.promotion.info": "Hai un codice promozionale di una delle nostre promozioni che vorresti riscattare per questo ordine?",
    "checkout.promotion.placeholder": "Il tuo codice promozionale",
    "checkout.promotion.notMinValue": "Il valore minimo d'ordine di {{minValue}} € per il codice promozionale attuale non è ancora stato raggiunto.",
    "checkout.promotion.invalid": "Il codice promozionale inserito non è (o non è più) valido",
    "checkout.promotion.redeem": "Riscattare",
    "account.order.rebuy": "Ordina di nuovo",
    "account.ui.company": "Dettagli del conto",
    "account.ui.companyWant": "Voglio diventare un cliente aziendale",
    "account.ui.companyIs": "Lei è un cliente aziendale e {{business}} registrato.",
    "account.ui.companyContact": "Dettagli del contatto",
    "account.ui.companyAddress": "Indirizzo standard",
    "account.ui.companyAddOn": "Informazioni estese",
    "account.ui.companyType": "Vorrei registrarmi come",
    "account.ui.company.upload": "Si prega di caricare i seguenti documenti:",
    "account.ui.company.upload.Heilpraktiker": "Il tuo certificato di praticante alternativo e la conferma della pratica (conferma della pratica).",
    "account.ui.company.upload.Apotheker": "La sua licenza per praticare la farmacia.",
    "account.ui.company.upload.Wiederverkäufer": "La tua licenza commerciale.",
    "account.ui.company.healer": "HeilpraktikerIn",
    "account.ui.company.pharmacy": "Farmacia",
    "account.ui.company.reseller": "Rivenditore",
    "account.ui.deliveryAddress": "Indirizzo di consegna",
    'account.ui.makeDeliveryAddress': 'Creare un indirizzo di consegna diverso',
    "account.company.contactName": "Contatto standard",
    "account.company.address.setDefault": "Impostare questo indirizzo come indirizzo predefinito",
    "account.company.address.isDefault": "Questo indirizzo è anche l'indirizzo di fatturazione e di consegna.",
    "account.company.address.isDeliveryDefault": "Questo indirizzo è l'indirizzo di consegna predefinito.",
    "account.company.address.deliveryDefault": "Questo indirizzo è l'indirizzo di consegna predefinito.",
    "user.ui.accountMenuCompanySettings": "Informazioni sull'azienda",
    "user.ui.accountMenuWishlist": "Nella mia lista di controllo",
    "user.ui.removeFromWishlist": "Rimuovi dalla lista di controllo",
    "user.ui.accountOrdersEmpty": "Non hai ancora ordinato nulla.",
    "user.ui.accountMenuWishlistEmpty": "Non hai ancora aggiunto nessun prodotto al tuo blocco note.",
    "user.genderToSalutation.male": "Il Sig.",
    "user.genderToSalutation.female": "Signora.",
    "nutritionTable.title": "Tabella nutrizionale",
    "nutritionTable.info": "è un prodotto naturale - tutti i dati sono valori medi.",
    "nutritionTable.100": "per 100 g",
    "nutritionTable.portion": "per porzione/contenuto",
    "nutritionTable.energy": "Energia",
    "nutritionTable.fat": "Fat",
    "nutritionTable.fatSaturated": "di cui acidi grassi saturi",
    "nutritionTable.carb": "Carboidrati",
    "nutritionTable.sugar": "il suo zucchero",
    "nutritionTable.protein": "Proteina (Proteine)",
    "nutritionTable.salt": "Sale",
    "offer.ui.rejectOfferMessage": "L'offerta è respinta perché:",
    "offer.ui.offerSent": "Offerta spedita!",
    "offer.ui.offerRejected": "L'offerta è stata rifiutata!",
    "shoppingCart.isLocked": "Il carrello non può semplicemente cambiare (ad esempio perché è un'offerta). Dopo aver completato l'ordine, tutto funziona di nuovo normalmente.",
    "user.company.newCustomerAccount": "Creare un conto per l'ordine di acquisto",
    "user.company.noCustomerValidation": "Il tuo account dell'ordine non è ancora stato attivato.",
    "user.company.noCustomerYet": "Sei registrato ma non hai ancora effettuato un ordine. Crea un account per poter ordinare."
  });
});