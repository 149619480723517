define('webapp/locales/en/translations', ['exports', 'webapp/locales/en/translations-base'], function (exports, _translationsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    'search.searchBarPlaceholder': 'Search for...',
    'application.ui.back': 'back',
    'search.searchBarPlaceholderMobile': "Product search...",
    'search.searchBarPlaceholderJobs': "Search for jobs. ",
    'search.searchBarPlaceholderEvents': "Search for events. ",
    'search.searchBarPlaceholderDefault': "Search... ",
    'search.searchBarPlaceholderGeoSearch': 'Your location...',
    'search.backToResults': 'Back to results',
    'noResults': 'No results',
    'blog.ui.noCategory': 'Posts',
    'widgets.categoryFilter.reset': 'Reset filters',
    'widgets.shoppingCart.wishlist': 'Wishlist',
    'widgets.shoppingCart.wishlist.empty': 'Your wishlist is empty',
    'widgets.shoppingCart.cart': 'Cart',
    'entries.entry.ui.products.allCategories': 'Show all products',
    'entries.entry.ui.products.detail': 'Details',
    'entries.entry.ui.products.checkOutShow': 'Show cart',
    'entries.entry.ui.products.checkOut': 'Go to checkout',
    'entries.entry.ui.products.chooseVariant': 'Please choose an option',
    'entries.entry.ui.products.notAvailable': 'not available',
    'entries.entry.ui.products.crosselling.title': 'More interesting products',
    'entries.entry.ui.products.relatedArticles.title': 'Additional product information',
    'entries.entry.ui.products.delivery.red': 'undeliverable',
    'entries.entry.ui.products.delivery.yellow': 'Currently not in Stock. Extended delivery period.',
    'entries.entry.ui.products.delivery.green': 'Product in stock in sufficient quantity',
    'article.ui.noCategory': 'News',
    'user.ui.accountMenuWishlist': 'Favorites',
    'user.ui.removeFromWishlist': 'Remove from Favorites',
    'checkout.delivery.pickUp': 'Pick up at our locations.',
    'checkout.delivery.standard': 'Will be shipped on ',
    'checkout.promotion.info': 'Do you have a promotion code?',
    'checkout.promotion.notMinValue': 'The minimum order value of € {{minValue}} for the current promo code has not yet been reached.',
    'checkout.promotion.redeem': 'Redeem',
    'checkout.promotion.placeholder': 'Your code',
    'checkout.promotion.invalid': 'The entered promotion code is no longer valid',
    'checkout.cart.additionalCosts': 'additional costs and discounts',
    'checkout.cart.checkoutWithAccount': 'Checkout with my account details',
    'account.ui.company': 'billing address',
    'account.ui.companyContact': 'contact details',
    'account.ui.companyAddOn': 'expanded details',
    'account.ui.deliveryAddress': 'create different shipping address',
    'account.ui.makeDeliveryAddress': 'abweichende Lieferadresse anlegen',
    'user.ui.accountMenuCompanySettings': 'company details',
    'account.ui.companyWant': 'I want to become a business customer',
    'account.company.address.isDefault': 'This address is the billing and delivery address.',
    'account.company.address.setDefault': 'Set this address as default address',
    'checkout.summary.withAccount': 'You order with the customer account settings of ',

    "entries.entry.ui.products.notOrderable": "cannot be ordered at the moment.",
    "entries.entry.ui.products.chooseVariantMobile": "← selection",
    "entries.products.ui.combinationNotAvailable": "Product not available in this combination",
    "product.ui.content": "Content",
    "product.ui.ingredients": "Content",
    "product.ui.ingredientsFood": "Ingredients",
    "product.ui.storage": "Storage instructions",
    "product.ui.taste": "Taste",
    "product.ui.origin": "Bee Happy Guarantee",
    "product.ui.dosage": "Recommended dosage",
    "product.ui.application": "Recommendation for use",
    "product.ui.misc": "Other information",
    "product.ui.technicalData": "Technical data and further information",
    "product.ui.pcs": "Piece",
    "product.ui.bought": "purchased",
    "product.ui.boughtLatest": "last",
    "product.ui.boughtPre": "You have this product",
    "product.ui.collect": "Order now here and pick up at these sales points",
    "product.ui.crosselling": "This might also interest you",
    "product.ui.crossellingReverse": "Customers who were interested in this product also bought",
    "product.ui.honeyInfo": "<p><span>Honey crystallizes! </span>This is a natural quality feature.</p><p><span>Honey crystallizes? </span>Place the jar in a water bath with a maximum of 40°C. The honey becomes liquid again.</p>",
    "product.ui.ingredientsBio": "Ingredients from controlled organic cultivation",
    "product.ui.cartEmpty": "You do not have any items in your shopping cart yet.",
    "product.ui.productOverview": "To the overview of all products on offer.",
    "product.ui.multipleSellingUnits": "Available in several sizes",
    "product.ui.information": "Further information",
    "product.ui.ecoCertText": "BIO certification",
    "product.ui.deliveryInfo": "Delivery note",
    "application.ui.alreadyFavorite": "This article is on your watchlist",
    "article.ui.noCategoryProduct": "Things to know",
    "category.ui.field.leasingWeekDuration": "Rental period",
    "category.ui.field.weeks": "Weeks",
    "category.ui.field.pcs": "Piece",
    "category.ui.field.productSize": "Size",
    "category.ui.field.material": "Material",
    "category.ui.field.honigArt": "Honey type",
    "category.ui.field.geleRoyale": "Royal jelly Percentage",
    "category.ui.field.%": "%",
    "user.ui.registerMessage": "Create a user account and benefit from many advantages and information for our customers.",
    "page.ui.privacy": "Data protection",
    "page.ui.terms": "General Terms and Conditions (GTC)",
    "page.ui.revocation": "Terms of withdrawal",
    "checkout.delivery.deliveryDate": "and expected to be delivered on",
    "checkout.delivery.express": "Will be shipped fresh on",
    "checkout.delivery.expressNotPossible": "You have entered a billing or shipping address with which express shipping is not possible.",
    "checkout.cart.customerLocked": "Your customer account has been locked. Please contact the support.",
    "checkout.cart.delete": "Delete shopping cart",
    "checkout.cart.promocode": "Promo Code:",
    "checkout.cart.customerComment": "Customer comment",
    'checkout.cart.customerCommentInfo': 'If you have any comments on the order, please enter an additional telephone contact address in your comment.',
    "checkout.cart.addPosition": "Add a quote item",
    "checkout.cart.convertOffer": "convert to offer",
    "checkout.order.comment": "Your comment on the order",
    "account.order.rebuy": "Order again",
    "account.ui.companyIs": "You are a corporate customer and {{business}} registered.",
    "account.ui.companyAddress": "Standard address",
    "account.ui.companyType": "I would like to register as",
    "account.ui.company.upload": "Please upload the following documents:",
    "account.ui.company.upload.Heilpraktiker": "Your alternative practitioner certificate and confirmation of practice (confirmation of practice).",
    "account.ui.company.upload.Apotheker": "Your license as a pharmacist.",
    "account.ui.company.upload.Wiederverkäufer": "Your business license.",
    "account.ui.company.healer": "HeilpraktikerIn",
    "account.ui.company.pharmacy": "Pharmacy",
    "account.ui.company.reseller": "Reseller",
    "account.company.contactName": "Standard contact",
    "account.company.address.isDeliveryDefault": "This address is the default delivery address.",
    "account.company.address.deliveryDefault": "This address is the default delivery address.",
    "user.ui.accountOrdersEmpty": "You haven't ordered anything yet.",
    "user.ui.accountMenuWishlistEmpty": "You have not added any products to your notepad yet.",
    "user.genderToSalutation.male": "Mr.",
    "user.genderToSalutation.female": "Ms.",
    "nutritionTable.title": "Nutrition table",
    "nutritionTable.info": "is a natural product - all data are average values.",
    "nutritionTable.100": "per 100 g",
    "nutritionTable.portion": "per portion/content",
    "nutritionTable.energy": "Energy",
    "nutritionTable.fat": "Fat",
    "nutritionTable.fatSaturated": "thereof saturated fatty acids",
    "nutritionTable.carb": "Carbohydrates",
    "nutritionTable.sugar": "thereof sugar",
    "nutritionTable.protein": "Protein (Proteins)",
    "nutritionTable.salt": "Salt",
    "offer.ui.rejectOfferMessage": "The offer is rejected because:",
    "offer.ui.offerSent": "Offer shipped!",
    "offer.ui.offerRejected": "The offer was rejected!",
    "shoppingCart.isLocked": "The shopping cart can not just change (eg because it is an offer). After completing the order, everything works again as normal.",
    "user.company.newCustomerAccount": "Create a purchase order account",
    "user.company.noCustomerValidation": "Your order account has not yet been activated.",
    "user.company.noCustomerYet": "You are registered but have not placed an order yet. Create an order account to be able to order."
  });
});