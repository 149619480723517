define("webapp/locales/sv/translations", ["exports", "webapp/locales/sv/translations-base"], function (exports, _translationsBase) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    "search.searchBarPlaceholderMobile": "produktsökning",
    'application.ui.back': 'tillbaka',
    "search.searchBarPlaceholderJobs": "Sök efter jobb eller i deras beskrivning.",
    "search.searchBarPlaceholderEvents": "Sök efter datum eller evenemang.",
    "search.searchBarPlaceholderDefault": "Sök...",
    "search.searchBarPlaceholderGeoSearch": "Din plats...",
    "search.backToResults": "Tillbaka till resultatlistan",
    "noResults": "Inga resultat",
    "blog.ui.noCategory": "Blogginlägg",
    "widgets.categoryFilter.reset": "Återställ filter",
    "widgets.shoppingCart.wishlist": "Memo och önskelista",
    "widgets.shoppingCart.cart": "Varukorg",
    "entries.entry.ui.products.allCategories": "Visa alla produkter",
    "entries.entry.ui.products.detail": "Välj variant",
    "entries.entry.ui.products.notOrderable": "kan inte beställas för tillfället.",
    "entries.entry.ui.products.checkOutShow": "Visa kundvagn",
    "entries.entry.ui.products.checkOut": "Fortsätt till kassan",
    "entries.entry.ui.products.chooseVariant": "Välj en variant",
    "entries.entry.ui.products.chooseVariantMobile": "← urval",
    "entries.entry.ui.products.notAvailable": "inte tillgänglig",
    "entries.entry.ui.products.crosselling.title": "Produkter som kan intressera dig",
    "entries.entry.ui.products.relatedArticles.title": "Ytterligare information om denna produkt",
    "entries.entry.ui.products.delivery.red": "Produkten är för närvarande inte tillgänglig",
    "entries.entry.ui.products.delivery.yellow": "Produkt i lager. Eventuellt längre leveranstid.",
    "entries.entry.ui.products.delivery.green": "Produkten finns i lager i tillräcklig mängd",
    "entries.products.ui.combinationNotAvailable": "Produkten finns inte i denna kombination",
    "search.searchBarPlaceholder": "Sök efter...",
    "product.ui.content": "Innehåll",
    "product.ui.ingredients": "Innehåll",
    "product.ui.ingredientsFood": "Ingredienser",
    "product.ui.storage": "Lagringsanvisningar",
    "product.ui.taste": "Smaka på",
    "product.ui.origin": "Bee Happy-garanti",
    "product.ui.dosage": "Rekommenderad dosering",
    "product.ui.application": "Rekommendation för användning",
    "product.ui.misc": "Övriga upplysningar",
    "product.ui.technicalData": "Tekniska data och ytterligare information",
    "product.ui.pcs": "Stycke",
    "product.ui.bought": "köpt",
    "product.ui.boughtLatest": "senast",
    "product.ui.boughtPre": "Du har denna produkt",
    "product.ui.collect": "Beställ nu här och hämta på dessa försäljningsställen",
    "product.ui.crosselling": "Detta kan också intressera dig",
    "product.ui.crossellingReverse": "Kunder som var intresserade av denna produkt köpte också",
    "product.ui.honeyInfo": "<p><span>Honung kristalliseras! </span>Detta är en naturlig kvalitetsegenskap.</p><p><span>Kristalliserar honung? </span>Placera burken i ett vattenbad med högst 40 °C. Honungen blir flytande igen.</p>",
    "product.ui.ingredientsBio": "Ingredienser från kontrollerad ekologisk odling",
    "product.ui.multipleSellingUnits": "Finns i flera storlekar",
    "product.ui.information": "Ytterligare information",
    "product.ui.ecoCertText": "BIO-certifiering",
    "product.ui.deliveryInfo": "Leveransmeddelande",
    "application.ui.alreadyFavorite": "Den här artikeln finns på din bevakningslista",
    "article.ui.noCategory": "Nyheter",
    "article.ui.noCategoryProduct": "Saker att veta",
    "category.ui.field.leasingWeekDuration": "Hyrestider",
    "category.ui.field.weeks": "Veckor",
    "category.ui.field.pcs": "Stycke",
    "category.ui.field.productSize": "Storlek",
    "category.ui.field.material": "Material",
    "category.ui.field.honigArt": "Typ av honung",
    "category.ui.field.geleRoyale": "Royal jelly Procentuell andel",
    "category.ui.field.%": "%",
    "user.ui.registerMessage": "Skapa ett användarkonto och dra nytta av många fördelar och information för våra kunder.",
    "page.ui.privacy": "Uppgiftsskydd",
    "page.ui.terms": "Allmänna villkor (GTC)",
    "page.ui.revocation": "Villkor för återkallande",
    "checkout.delivery.deliveryDate": "och förväntas levereras den",
    "checkout.delivery.standard": "Skickas den",
    "checkout.delivery.express": "Skickas färskt på",
    "checkout.delivery.pickUp": "Hämtning på plats",
    "checkout.delivery.expressNotPossible": "Du har angett en fakturerings- eller leveransadress med vilken expressleverans inte är möjlig.",
    "checkout.summary.withAccount": "Du beställer med kundkontoinställningarna för",
    "checkout.cart.checkoutWithAccount": "Kassa med mina kontouppgifter",
    "checkout.cart.customerLocked": "Ditt kundkonto har låsts. Vänligen kontakta supporten.",
    "checkout.cart.additionalCosts": "ytterligare kostnader och rabatter",
    "checkout.cart.delete": "Ta bort kundvagnen",
    "checkout.cart.promocode": "Promo Code:",
    "checkout.cart.customerComment": "Kundkommentar",
    "checkout.cart.addPosition": "Lägg till en offertpost",
    "checkout.cart.convertOffer": "konvertera till erbjudande",
    "checkout.order.comment": "Din kommentar till beställningen",
    "checkout.promotion.info": "Har du en kampanjkod från en av våra kampanjer som du vill använda för denna beställning?",
    "checkout.promotion.notMinValue": "Det minsta beställningsvärdet på {{minValue}} euro för den aktuella kampanjkoden har ännu inte uppnåtts.",
    "checkout.promotion.placeholder": "Din kampanjkod",
    "checkout.promotion.invalid": "Den angivna kampanjkoden är inte (eller inte längre) giltig.",
    "checkout.promotion.redeem": "Lös in",
    "account.order.rebuy": "Beställ igen",
    "account.ui.company": "Kontouppgifter",
    "account.ui.companyWant": "Jag vill bli företagskund",
    "account.ui.companyIs": "Du är en företagskund och {{business}} registrerad.",
    "account.ui.companyContact": "Kontaktuppgifter",
    "account.ui.companyAddress": "Standardadress",
    "account.ui.companyAddOn": "Utökad information",
    "account.ui.companyType": "Jag vill registrera mig som",
    "account.ui.company.upload": "Ladda upp följande dokument:",
    "account.ui.company.upload.Heilpraktiker": "Ditt intyg om alternativpraktiker och din bekräftelse på att du praktiserar (bekräftelse på att du praktiserar).",
    "account.ui.company.upload.Apotheker": "Din legitimation som apotekare.",
    "account.ui.company.upload.Wiederverkäufer": "Din företagslicens.",
    "account.ui.company.healer": "HeilpraktikerIn",
    "account.ui.company.pharmacy": "Apotek",
    "account.ui.company.reseller": "Återförsäljare",
    "account.ui.deliveryAddress": "Leveransadress",
    'account.ui.makeDeliveryAddress': 'Skapa en annan leveransadress',
    "account.company.contactName": "Standardkontakt",
    "account.company.address.setDefault": "Ange den här adressen som standardadress",
    "account.company.address.isDefault": "Denna adress är också fakturerings- och leveransadress.",
    "account.company.address.isDeliveryDefault": "Denna adress är standardleveransadressen.",
    "account.company.address.deliveryDefault": "Denna adress är standardleveransadressen.",
    "user.ui.accountMenuCompanySettings": "Information om företaget",
    "user.ui.accountMenuWishlist": "I min bevakningslista",
    "user.ui.removeFromWishlist": "Ta bort från bevakningslistan",
    "user.ui.accountOrdersEmpty": "Du har inte beställt något ännu.",
    "user.ui.accountMenuWishlistEmpty": "Du har ännu inte lagt till några produkter i ditt anteckningsblock.",
    "user.genderToSalutation.male": "Herr.",
    "user.genderToSalutation.female": "Ms.",
    "nutritionTable.title": "Näringstabell",
    "nutritionTable.info": "är en naturprodukt - alla uppgifter är medelvärden.",
    "nutritionTable.100": "per 100 g",
    "nutritionTable.portion": "per del/innehåll",
    "nutritionTable.energy": "Energi",
    "nutritionTable.fat": "Fett",
    "nutritionTable.fatSaturated": "därav mättade fettsyror",
    "nutritionTable.carb": "Kolhydrater",
    "nutritionTable.sugar": "därav socker",
    "nutritionTable.protein": "Protein (proteiner)",
    "nutritionTable.salt": "Salt",
    "offer.ui.rejectOfferMessage": "Erbjudandet förkastas på grund av att:",
    "offer.ui.offerSent": "Erbjudandet skickas!",
    "offer.ui.offerRejected": "Erbjudandet avvisades!",
    "shoppingCart.isLocked": "Kundvagnen kan inte bara ändras (t.ex. för att det är ett erbjudande). Efter att ha slutfört beställningen fungerar allting som vanligt igen.",
    "user.company.newCustomerAccount": "Skapa ett inköpsorderkonto",
    "user.company.noCustomerValidation": "Ditt beställningskonto har ännu inte aktiverats.",
    "user.company.noCustomerYet": "Du är registrerad men har ännu inte gjort någon beställning. Skapa ett beställningskonto för att kunna beställa."
  });
});