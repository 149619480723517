define('webapp/locales/de/translations', ['exports', 'webapp/locales/de/translations-base'], function (exports, _translationsBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _extends({}, _translationsBase.default, {
    'search.searchBarPlaceholderMobile': "Produksuche...",
    'application.ui.back': 'zurück',
    'search.searchBarPlaceholderJobs': "Suchen Sie nach Jobs oder in deren Beschreibung. ",
    'search.searchBarPlaceholderEvents': "Suchen Sie nach Terminen oder Veranstaltungen. ",
    'search.searchBarPlaceholderDefault': "Suche... ",
    'search.searchBarPlaceholderGeoSearch': 'Ihr Standort...',
    'search.backToResults': 'Zurück zur Ergebnisliste',
    'noResults': 'Keine Ergebnisse',
    'blog.ui.noCategory': 'Blogbeitrag',
    'widgets.categoryFilter.reset': 'Filter zurücksetzen',
    'widgets.shoppingCart.wishlist': 'Merk- und Wunschliste',
    'widgets.shoppingCart.wishlist.empty': 'Sie haben noch keine Produkte auf Ihrer Merk- und Wunschliste',
    'widgets.shoppingCart.cart': 'Warenkorb',
    'entries.entry.ui.products.allCategories': 'Alle Produkte anzeigen',
    'entries.entry.ui.products.detail': 'Variante auswählen',
    'entries.entry.ui.products.notOrderable': 'kann derzeit nicht bestellt werden.',
    'entries.entry.ui.products.checkOutShow': 'Warenkorb anzeigen',
    'entries.entry.ui.products.checkOut': 'Weiter zur Kasse',
    'entries.entry.ui.products.chooseVariant': 'Bitte wählen Sie eine Variante aus',
    'entries.entry.ui.products.chooseVariantMobile': '← auswahl',
    'entries.entry.ui.products.notAvailable': 'nicht verfügbar',
    'entries.entry.ui.products.crosselling.title': 'Produkte, die Sie interessieren könnten',
    'entries.entry.ui.products.relatedArticles.title': 'Weiterführende Informationen zu diesem Produkt',
    'entries.entry.ui.products.delivery.red': 'Produkt zur Zeit nicht lieferbar',
    'entries.entry.ui.products.delivery.yellow': 'Produkt im Zulauf. Eventuell längere Lieferzeit.',
    'entries.entry.ui.products.delivery.green': 'Produkt in ausreichender Stückzahl auf Lager',
    'entries.products.ui.combinationNotAvailable': 'Produkt in dieser Kombination nicht verfügbar',
    'search.searchBarPlaceholder': 'Suche nach...',
    'product.ui.content': 'Inhalt',
    'product.ui.ingredients': 'Inhalt',
    'product.ui.ingredientsFood': 'Zutaten',
    'product.ui.storage': 'Aufbewahrungshinweise',
    'product.ui.taste': 'Geschmack',
    'product.ui.origin': 'Bee-Happy-Garantie',
    'product.ui.dosage': 'Einnahme-Empfehlung',
    'product.ui.application': 'Anwendungs-Empfehlung',
    'product.ui.misc': 'Sonstige Information',
    'product.ui.technicalData': 'Technische Daten und weitere Infos',
    'product.ui.pcs': 'Stück',
    'product.ui.bought': 'gekauft',
    'product.ui.boughtLatest': ' zuletzt',
    'product.ui.boughtPre': 'Sie haben dieses Produkt',
    'product.ui.collect': 'Jetzt hier bestellen und an diesen Verkaufsstellen abholen',
    'product.ui.crosselling': 'Das könnte Sie auch interessieren',
    'product.ui.crossellingReverse': 'Kunden, die sich für dieses Produkt interessiert haben, kauften auch',
    'product.ui.honeyInfo': '<p><span>Honig kristallisiert! </span>Das ist ein natürliches Qualitätsmerkmal.</p><p><span>Honig kristallisiert? </span>Stellen Sie das Glas in ein Wasserbad mit maximal 40°C. Der Honig wird wieder flüssig.</p>',
    'product.ui.ingredientsBio': 'Zutaten aus kontrolliert biologischem Anbau',
    'product.ui.multipleSellingUnits': 'In mehreren Größen erhältlich',
    'product.ui.information': 'Weiterführende Informationen',
    'product.ui.ecoCertText': 'BIO-Zertifizierung',
    'product.ui.deliveryInfo': 'Lieferhinweis',
    'product.ui.bundleItems': 'Im Set enthalten',
    'product.ui.bundleSet': 'Im folgenden Set enthalten',
    'product.ui.noIndividualPurchase': 'Kann nicht einzeln bestellt werden. Nur im Set erhältlich.',
    'product.ui.pickUp': 'Jetzt hier bestellen und an diesen Stellen abholen',
    'product.ui.cartEmpty': 'Du hast noch keine Produkte im Einkaufskorb.',
    'product.ui.productOverview': 'Alle Produkte in der Übersicht',
    'article.ui.noCategory': 'News',
    'article.ui.noCategoryProduct': 'Wissenswertes',
    'category.ui.field.leasingWeekDuration': 'Mietdauer',
    'category.ui.field.weeks': 'Wochen',
    'category.ui.field.pcs': 'Stück',
    'category.ui.field.productSize': 'Größe',
    'category.ui.field.material': 'Material',
    'category.ui.field.honigArt': 'Honigsorte',
    'category.ui.field.geleRoyale': 'Gelée Royale Anteil in %',
    'category.ui.field.%': '%',
    'user.ui.registerMessage': 'Als Privatkunde können Sie einfach bestellen und im Bestellvorgang an der Kasse angeben, dass Sie ein Benutzerkonto haben möchten. <br><br>Wenn Sie Heilpraktiker/-in, Wiederverkäufer/-in oder eine Apotheke sind, können Sie hier ein Geschäftskundenkonto anlegen und von vielen Vorteilen und Informationen profitieren. <br><br>Beachten Sie bitte, dass für ein Geschäftskonto Unterlagen notwendig sind.',
    'page.ui.privacy': 'Datenschutz',
    'page.ui.terms': 'Allgemeinen Geschäftsbedingungen (AGB)',
    'page.ui.revocation': 'Widerrufsbedingungen',
    'checkout.delivery.deliveryDate': 'und voraussichtlich geliefert am ',
    'checkout.delivery.standard': 'Lieferung bis ',
    'checkout.delivery.express': 'Express-Lieferung bis ',
    'checkout.delivery.pickUp': 'Vor Ort abholen',
    'checkout.delivery.expressNotPossible': 'Sie haben eine Rechnungs- oder Lieferadresse angegeben, mit der kein Expressversand möglich ist.',
    'checkout.summary.withAccount': 'Sie bestellen mit den Kundenkonto-Einstellungen von ',
    'checkout.cart.checkoutWithAccount': 'Zur Kasse mit meinen Kontodaten',
    'checkout.cart.customerLocked': 'Ihr Kundenkonto wurde gesperrt. Bitte wenden sie sich an den Support.',
    'checkout.cart.additionalCosts': 'zusätzliche Kosten und Rabatte',
    'checkout.cart.delete': 'Warenkorb löschen',
    'checkout.cart.promocode': 'Promo-Code:',
    'checkout.cart.customerComment': 'Kundenkommentar',
    'checkout.cart.customerCommentInfo': 'Sollten Sie eine Anmerkung zur Bestellung haben, geben Sie in Ihrer Anmerkung bitte eine zusätzliche telefonische Kontaktadresse an.',
    'checkout.cart.addPosition': 'Angebotsposition hinzufügen',
    'checkout.cart.convertOffer': 'in Angebot umwandeln',
    'checkout.order.comment': 'Ihre Anmerkung zur Bestellung',
    'checkout.promotion.info': 'Haben Sie einen Promotioncode aus einer unserer Aktionen, den Sie für diese Bestellung einlösen wollen?',
    'checkout.promotion.notMinValue': 'Der Mindestbestellwert von € {{minValue}} für den aktuellen Promo-Code ist noch nicht erreicht.',
    'checkout.promotion.placeholder': 'Ihr Promotioncode',
    'checkout.promotion.invalid': 'Der eingegebene Promotionode ist nicht (mehr) gültig',
    'checkout.promotion.redeem': 'Einlösen',
    'account.order.rebuy': 'Erneut bestellen',
    'account.ui.company': 'Kontoangaben',
    'account.ui.companyWant': 'Ich will Firmenkunde werden',
    'account.ui.companyIs': 'Sie sind Firmenkunde und als {{business}} eingetragen.',
    'account.ui.companyContact': 'Kontaktangaben',
    'account.ui.companyAddress': 'Standardadresse',
    'account.ui.companyAddOn': 'Erweiterte Angaben',
    'account.ui.companyType': 'Ich möchte mich registrieren als',
    'account.ui.company.upload': 'Bitte folgende Dokumente hochladen:',
    'account.ui.company.upload.Heilpraktiker': 'Ihr Heilpraktiker-Zertifikat und die Bestätigung der Ausübung (Praxisbestätigung).',
    'account.ui.company.upload.Apotheker': 'Ihre Zulassung als Apotheker.',
    'account.ui.company.upload.Wiederverkäufer': 'Ihren Gewerbeschein.',
    'account.ui.company.healer': 'HeilpraktikerIn',
    'account.ui.company.pharmacy': 'Apotheke',
    'account.ui.company.reseller': 'WiederverkäuferIn',
    'account.ui.company.uploadedDocuments': 'Ihre hochgeladenen Dateien',
    'account.ui.company.uploadedDocuments.info': 'Wenn Sie ein Dokument hochgeladen haben, geben Sie dem Dokument bitte einen Titel und eine kurze Beschreibung. Klicken Sie auf das Stiftsymbol in der oberen linken Ecke des Dokuments, um Titel und Beschreibung einzugeben. Wichtig: Speichern Sie Ihre Änderungen.',
    'account.ui.company.uploadedDocument.title': 'Bitte geben Sie den Dokument einen aussagekräftigen Titel.',
    'account.ui.company.uploadedDocument.description': 'Bitte beschreiben Sie kurz, um welche Art von Dokument es sich handelt.',
    'account.ui.deliveryAddress': 'Lieferadresse',
    'account.ui.makeDeliveryAddress': 'abw. Lieferadresse',
    'account.company.contactName': 'Standard-Ansprechpartner',
    'account.company.address.setDefault': 'Diese Adresse als Standardadresse festlegen',
    'account.company.address.isDefault': 'Diese Adresse ist gleichzeitig Rechnungs- und Lieferadresse.',
    'account.company.address.isDeliveryDefault': 'Diese Adresse ist die Standard Lieferadresse.',
    'account.company.address.deliveryDefault': 'Diese Adresse ist die Standard-Lieferadresse.',
    'user.ui.accountMenuCompanySettings': 'Unternehmensangaben',
    'user.ui.accountMenuWishlist': 'In meiner Merkliste',
    'user.ui.removeFromWishlist': 'Aus der Merkliste entfernen',
    'user.ui.accountOrdersEmpty': 'Sie haben noch nichts bestellt.',
    'user.ui.accountMenuWishlistEmpty': 'Sie haben noch keine Produkte zu Ihrerm Merkzettel hinzugefügt.',
    "user.ui.documents": "Produktinfos",
    'user.genderToSalutation.male': 'Herr',
    'user.genderToSalutation.female': 'Frau',
    'user.genderToSalutation.diverse': '',
    'nutritionTable.title': 'Nährwerttabelle',
    'nutritionTable.info': 'ist ein Naturprodukt – Alle Angaben sind Durchschnittswerte.',
    'nutritionTable.100': 'je 100 g',
    'nutritionTable.portion': 'je Portion/Inhalt',
    'nutritionTable.energy': 'Energie',
    'nutritionTable.fat': 'Fett',
    'nutritionTable.fatSaturated': 'davon gesättigte Fettsäuren',
    'nutritionTable.carb': 'Kohlenhydrate',
    'nutritionTable.sugar': 'davon Zucker',
    'nutritionTable.protein': 'Eiweiß (Proteine)',
    'nutritionTable.salt': 'Salz',
    'offer.ui.rejectOfferMessage': 'Das Angebot wird abgelehnt weil:',
    'offer.ui.offerSent': 'Angebot versendet!',
    'offer.ui.offerRejected': 'Das Angebot wurde abgelehnt!',
    'shoppingCart.isLocked': 'Der Warenkorb lässt sich gerade Nicht änder (z.B. weil es sich um ein Angebot handelt). Nach Abschluss der Bestellung funktioniert alles wieder wie Normal.',
    "user.company.newCustomerAccount": "Ein Bestellkonto anlegen",
    "user.company.noCustomerValidation": "Ihr Bestellkonto wurde noch nicht freigeschaltet.",
    "user.company.noCustomerYet": "Sie sind registriert, haben aber noch keine Bestellung gemacht. Legen Sie ein Bestellkonto an, um bestellen zu können.",
    "country.DE": "Inland",
    "country.EU": "EU-Ausland",
    "country.NONEU": "Weltweit"
  });
});